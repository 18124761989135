

















import Vuetify from 'vuetify'
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'About',
  data() {
    return {
      vuetifyVersion: Vuetify.version,
      vueVersion: Vue.version,
      uuid: localStorage.getItem('maintenance-uuid'),
      url: this.$store.state.ajaxurl
    }
  },
  methods: {
    envoyerSMS() {
      axios.post(this.$store.state.ajaxurl + 'sms.php', {
        message: 'Belval : uuid=' + this.uuid
      })
    }
  }
})
